.phone-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    height: 100%; /* Ocupa todo el alto disponible en el Col */
  }
.phone {
  width: 250px;
  height: 450px;
  background-color: #000;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.screen {
  width: 90%;
  height: 90%;
  background-color: #fdfdfd;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  overflow: hidden; /* Asegura que nada se desborde fuera de 'screen' */
}

.questions-container {
  overflow-y: auto; /* Habilita scroll vertical si el contenido excede la altura */
  width: 100%; /* Ajusta esto según necesidades */
  height: 250px; /* Ajusta la altura según el diseño, puede ser menos dependiendo del espacio para otros elementos */
}

.customButton, .customButtonok, .customButtoncancel {
  margin-top: 10px; /* Asegura que los botones no estén pegados a las preguntas */
}
.customButton {
    background-color: #7B241C;
    color: white;
    height: 25px; /* Altura del botón */
    padding: 0 15px; /* Reducir el padding horizontal para un botón más estrecho */
    font-size: 8px; /* Tamaño de fuente más pequeño para el texto */
    display: flex;
    align-items: center; /* Centrar el texto y el icono verticalmente */
    justify-content: center;
  }
  
  .customButtonIcon {
    font-size: 10px; /* Tamaño del icono */
  }

  .customButtonok {
    background-color: #7B241C;
    color: white;
    height: 25px; /* Altura del botón */
    padding: 0 15px; /* Reducir el padding horizontal para un botón más estrecho */
    font-size: 8px; /* Tamaño de fuente más pequeño para el texto */
    display: flex;
    align-items: center; /* Centrar el texto y el icono verticalmente */
    justify-content: center;
  }
  .customButtoncancel {
    background-color: white;
    color: #7B241C;
    height: 25px; /* Altura del botón */
    padding: 0 15px; /* Reducir el padding horizontal para un botón más estrecho */
    font-size: 8px; /* Tamaño de fuente más pequeño para el texto */
    display: flex;
    align-items: center; /* Centrar el texto y el icono verticalmente */
    justify-content: center;
  }
  
  .customButtonIcon2 {
    font-size: 10px; /* Tamaño del icono */
  }


