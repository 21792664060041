.rows {
    padding: 0.3rem 0 0 0.3rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
}

.card-principal {
    background-color: var(--primary) !important;
    cursor: pointer;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
}
.card-principal > .ant-card-body {
    padding-bottom: 24px;
}

.space-principal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 1.2rem;
    padding: 0 !important;
    margin-bottom: 0;
}

@media screen and (min-width: 778px){
    .rows {
        padding: 16px 0px 16px 16px;
    }

    .card-principal {
        height: 250px;
    }
}