.rowStyle {
    padding: 0 16px !important;
    margin-right: 0px !important;
    background-color: #fff !important;
}

.collapseStyle {
    margin-bottom: 16px !important;
    padding: 0 !important;
}

@media only screen and (max-width: 768px) {
    .rowStyle {
        padding: 16px 5px !important;
    }
}