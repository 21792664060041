.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid white;
}

.editable-row:hover .editable-cell-value-wrap {
  /* padding: 4px 11px; */
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}