.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card {
    max-width: 500px;
    width: 100%;
    margin: 10px;
}

.containerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 100%;
}

.titulo {
    color: var(--primary) !important;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    line-height: 110%;
    margin: 1.52rem 0 .912rem 0;
    font-weight:lighter;
}

.icon {
    font-size: 2rem;
}