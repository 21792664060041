:root {
  --primary: rgb(106 40 49);
  --secondary: rgb(142 90 92);
  --tertiary: rgb(179 141 141);
  --background: #f0f0f0;
  --white: #fff;
  --black: #000;
  --text: rgb(73 66 66);
  --gray: #acacac;
  --overlay: rgb(141 141 141 / 0.322);
  --error: rgb(182, 0, 0);
  --warning: rgb(224 127 0);
  --success: rgb(0 173 23);
  --blue: rgb(0 109 173);
}

html {
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
  /* font-family: 'Montserrat' !important; */
}

body {
  margin: 0;
  /* font-family: 'Montserrat'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: var(--background) !important;
}

/* .disabled-input {
  pointer-events: none !important;
  opacity: 1 !important;
} */

/* .custom-container select[disabled] {
  pointer-events: none;
  opacity: 0.9;
  background-color: #ffffff;
  color: #000000;
} */

.custom-container *[disabled] {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.ant-select-disabled .ant-select-selector {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.ant-datePicker-disabled .ant-select-selector {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.ant-picker-disabled .ant-picker-input > * {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

/* Estilos para RangePicker deshabilitado */
.ant-picker.ant-picker-disabled {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.ant-select.ant-select-in-form-item-disable > * {
  pointer-events: none !important;
  opacity: 0.9 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.formulario > * input {
  text-transform: uppercase;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sanGregorioColor {
  color: var(--primary) !important;
}

.sanGregorioBorder {
  border: 1px solid var(--primary) !important;
}

.sanGregorioBkColor {
  background-color: var(--primary) !important;
}

.sanGregorioBkColorAlter {
  background-color: var(--white) !important;
}

.input-field .prefix.active {
  color: var(--primary) !important;
}

.logoFormulario {
  width: 100%;
}

.iconbutton {
  float: right;
  font-size: 1.4rem;
}

.bottomNav {
  background-color: var(--primary);
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 !important;
}

/* Estilos de tablas editable */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.red {
  color: #ee515b;
  font-weight: 600;
}
.blue {
  color: #3dbeee;
  font-weight: 600;
}
.green {
  color: #40bb94;
  font-weight: 600;
}
.yellow {
  color: rgb(223, 223, 78);
  font-weight: 600;
}

@media screen and (min-width: 992px) {
  /* .bottomNav {
    display: none;
  } */
}

.ant-card-actions {
  position: absolute;
  width: 100%;
  bottom: 0 !important;
}

/* .ant-card-body {
  padding-bottom: 0 !important;
} */

.leaflet-container {
  height: 60vh;
  position: relative;
}

.leaflet-bottom,
.leaflet-control,
.leaflet-pane,
.leaflet-top {
  z-index: 0 !important;
}
.ImagenRevisionFull {
  width: 100%;
  display: block;
  margin: auto;
}

.modalRevision-content-respuesta {
  background-color: var(--black);
  overflow-y: scroll;
  max-height: 400px;
}

.ant-drawer-body {
  padding: 24px 0 24px 0 !important;
}

.ant-tooltip {
  z-index: 2000 !important;
}
.ant-select-dropdown {
  z-index: 2001 !important;
}
.ant-picker-dropdown {
  z-index: 2001 !important;
}
.ant-dropdown {
  z-index: 2000 !important;
}
.ant-notification {
  z-index: 3000 !important;
}
.ant-modal-wrap {
  z-index: 2000 !important;
}
.ant-modal-mask {
  z-index: 2000 !important;
}
.ant-popover {
  z-index: 2001 !important;
}
.ant-message {
  z-index: 3000 !important;
}

.optionBreakWord .ant-select-item-option-content {
  white-space: break-spaces !important;
}
.ant-image-preview-wrap {
  z-index: 10999 !important;
}
.treeMenuSort {
  padding: 10px !important;
}

.treeMenuSort .ant-tree-treenode {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 5px !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 5px;
}
.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #d9d9d9;
}
.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.upload-button {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-seleccionable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hoverable:hover {
  background-color: var(--primary);
  color: white;
}

.modalZeroPadding .ant-modal-body {
  padding: 0;
}

.scrollableDiv {
  max-height: 70vh;
  overflow: hidden;
}

.scrollableDiv:hover {
  overflow: auto;
}

/*  SCROLL EN NAVEGADORES WEBKIT   */

.scrollableDiv::-webkit-scrollbar {
  width: 6px;
}

.scrollableDiv::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 3px;
}

/*  SCROLL EN NAVEGADORES COMO FIREFOX   */

.scrollableDiv::-moz-scrollbar {
  width: 6px;
}

.scrollableDiv::-moz-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 3px;
}
.ant-drawer {
  z-index: 2000 !important;
}
/*check en forma de boton*/
.download-checkbox {
  height: 2em;
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  cursor: pointer;
}

.download-checkbox:hover {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

/*Controles del modal de revición de las imagenes
.modalRevision-controles-grid{
  display: grid;
  justify-content: center;
}
.modalRevision-controles{
  display: block;
  margin: 10px;
}
.imagenControlButton{
  padding: 10px;
  background-color: var(--secondary);
  color: var(--white);
  border: 1px solid var(--primary);
}
.imagenControlButton:focus, .imagenControlButton:hover{
  background-color: var(--tertiary);
}
.modalRevision-titulo{
  color: var(--primary);
  font-size: 2rem;
  margin: 20px;
}
.modalRevision-respuesta{
  border: 1px solid var(--primary);
  margin: 0 10px 0 10px;
  padding: 0 20px 0 20px;
}
.modalRevision-imagen-seccion{
  border: 1px solid var(--primary);
}

.botonRevisionModalContenedor{
  margin: 20px auto 20px auto;
}

.botonRevisionModal{
  display: inline-block;
  padding: 2px 8px 2px 8px;
  margin: 4px;
  border: none;
}
.brmg{
  background-color: var(--primary);
  outline: 1px solid var(--primary);
  color: var(--white);
}
.brmc{
  background-color: var(--white);
  color: var(--primary);
  outline: 1px solid var(--primary);
}

#comentario_respuesta::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+
  color: gray;
  opacity: .9; /* Firefox
} */
div.ant-upload {
  width: 100%;
}
.leaflet-container {
  height: 63vh !important;
}

.ant-tabs-tab {
  border-radius: 20px !important;
}

.group_rebate {
  display: flex !important;
  gap: 5px;
}
.selected-row {
  background-color: rgb(192, 158, 165);
}

/*BOton de Indicador*/

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

ant-table [vt] > table > .ant-table-tbody > tr > td {
  padding: 8px;
}

.tabla-externa .ant-table-thead {
  background-color: #f0f0f0; /* color de fondo para la cabecera de la tabla externa */
}

.tabla-interna .ant-table-thead .ant-table-cell {
  background-color: #ddd1d1; /* color de fondo para la cabecera de la tabla interna */
}

.row-loading .ant-checkbox-inner {
  display: none;
}

.row-loading::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  /* o si prefieres usar el spinner de antd directamente */
  background: rgb(0 0 0 / 0.25) url(data:image/svg+xml,...) center/contain no-repeat;
}

@media only screen and (max-width: 776px) {
  .group_rebate {
    display: "flex" !important;
    flex-direction: column !important;
  }

  .scrollableDiv {
    overflow: auto;
  }
}

.custom-dragger .ant-upload-drag-container {
  display: none !important;
}

.custom-dragger .upload-text {
  display: none !important;
}
.approved {
  color: green;
}

.rejected {
  color: red;
}

.scrollable {
  height: 150px;
  overflow-y: "scroll" !important;
}

.commentContainer {
  margin-top: "2%";
}

.largeTag {
  font-size: 1em !important;
  padding: 10px 15px !important;
}

.ellipsis-column {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.ellipsis-column-250 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.row-red {
  background-color: red;
}

.scroll-column-scroll-150 {
  white-space: nowrap;
  overflow-x: auto;
  max-width: 150px;
}

.scroll-column-scroll-150::-webkit-scrollbar {
  width: 1px; /* Anchura del scrollbar */
  height: 5px;
  padding: 2px;
}

.scroll-column-scroll-150::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Color del "pulgar" del scrollbar */
  border-radius: 2px; /* Redondez de las esquinas del "pulgar" */
}

.scroll-column-scroll-150::-webkit-scrollbar-track {
  background-color: lightgrey; /* Color de la "pista" del scrollbar */
}

/* Para Firefox */
.scroll-column-scroll-150 {
  scrollbar-width: thin; /* "auto" o "thin" para hacerlo más delgado */
  scrollbar-color: darkgrey lightgrey; /* Colores para Firefox */
}

/* Para Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(248, 248, 248) gray;
}

/* Para otros navegadores basados en WebKit como Brave */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: gray;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(243, 238, 239);
  border-radius: 0px;
  border: 1px solid gray;
}

.scroll-column-scroll-250 {
  white-space: nowrap;
  overflow-x: auto;
  max-width: 350px;
}

.scroll-column-scroll-250::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  padding: 1px;
}

.scroll-column-scroll-250::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* Color del "pulgar" del scrollbar */
  border-radius: 1px; /* Redondez de las esquinas del "pulgar" */
}

.scroll-column-scroll-250::-webkit-scrollbar-track {
  background-color: rgb(236, 219, 219); /* Color de la "pista" del scrollbar */
}

/* Para Firefox */
.scroll-column-scroll-250 {
  scrollbar-width: thin; /* "auto" o "thin" para hacerlo más delgado */
  scrollbar-color: darkgrey rgb(224, 207, 207); /* Colores para Firefox */
}

/* CSS para reducir los márgenes en dispositivos móviles */
@media (max-width: 767px) {
  .chart-container {
    margin-left: -60px; /* Establece los márgenes a cero o el valor deseado para dispositivos móviles */
    margin-right: -24px;
  }
}
/* CSS para mantener los márgenes más grandes en pantallas grandes */
@media (min-width: 768px) {
  .chart-container {
    margin-left: 0px; /* Establece los márgenes a cero o el valor deseado para dispositivos móviles */
    margin-right: -24px;
  }
}

.recharts-legend-item-text {
  color: black !important; /* Establece el color del texto en negro */
  font-weight: 400;
}

.center_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center_spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Estilos personalizados para la tabla */
.customTbl {
  font-size: 9.5px; /* Tamaño de la fuente más pequeño */
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-gantt/styles/material.css";
