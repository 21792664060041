.rowStyle {
    padding: 16px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.rowTableStyle > td {
    padding: 2px 8px !important;
}

.rowSumaryStyle > td {
    padding: 3.5px 8px !important;
}

.alignTextRight {
    text-align: right;
}

.cellSumary {
    background-color: #fafafa;
}