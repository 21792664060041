.ant-transfer-list-header{
    height: 60px; /* Cambia la altura según tus necesidades */
}

.tranfer-table {
  width: 100%;
}

.card {
  margin: 10px;
}

@media (max-width: 768px) {
  .tranfer-table {
    display: block;
  }
  .ant-transfer-list-header{
    height: 80px; /* Cambia la altura según tus necesidades */
}
}
/* .ant-table-selection-column{
  pointer-events: all;
} */
