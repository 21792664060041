  
  .my-custom-select .ant-select-item-option-content {
    font-size: 11px; /* Tamaño de la fuente para las opciones desplegadas */
  }

  .custom-tooltip .ant-tooltip-inner {
    font-size: 10px;
    margin-bottom: '2px';
    padding-bottom: 2px; /* Tamaño de fuente personalizado */
  }


  .table-container {
    overflow-x: auto;
  }
  
  .fontzise{
    font-size: 10px;
  }
  /* .ant-table {
    width: 100%;
  }
  
  .ant-table-wrapper {
    overflow-x: auto;
  }
  
  .ant-table-container {
    overflow-x: auto;
  } */
  
/*   .ant-table-thead th {
    white-space: nowrap; /*Evita el corte de texto en las cabeceras de las columnas 
  } */
  
/*   .ant-table-tbody td {
    white-space: nowrap; /* Evita el corte de texto en las celdas de la tabla 
  } */