.events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: none;
  }
  .notes_month {
    font-size: 28px;
    text-align: center;
  }
  .notes_month section {
    font-size: 28px;
  }
  .card_prueba{
    border-radius: 15px ;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15)
  }
  .textSpacing {
    margin-left: 10px;
  }
  .textareaSpacing {
    margin-bottom: 20px;
  }
  .separacion{
    gap: 2;
  }
  