.RowFilters {
    padding: 16px 5px;
    margin-left: 0;
    margin-right: 0;
    background-color: #fff
}

@media screen and (min-width: 768px){
    .RowFilters {
        padding: 16px 22px;
        margin-left: 0;
        margin-right: 0;
        background-color: #fff
    }
}