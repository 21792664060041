/* .buttonWithIcon {
    border-radius: 0 !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
}

.buttonWithIconActive {
    border-radius: 0 !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    background-color: #45171e !important;
} */

.ulBottom {
    list-style: none;
    display: flex;
    flex: auto;
    padding: 0;
    margin: 0;
    background-color: var(--primary);
}

.liBottom {
    display: flex;
    flex: auto;
    justify-content: center;
    height: 100%;
}

.linkBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff !important;
    padding: 5px
}

.linkBottomActive {
    background-color: #45171e
}