/* .titlehead{
    .ant-table-thead{
        background-color: #F2D7D5;
    }
}*/

.bordercolumn{
    border-right: #EBEBEB 1px solid;
}

.bordercolumn
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 0em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}


.fomattext{
    width: auto;
    min-width: 100px;
    font-size: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.fontsize{
    font-size: 10px;
}


.bordercolumn .ant-table-expanded-row .ant-table-cell {
    display: flex;
    align-items: center; /* Alinea verticalmente el contenido */
}


.miFilaSeleccionada:hover {
    /* Estilos que se aplicarán cuando el mouse pase sobre el elemento */
    /* color: rgb(60, 13, 13); */
    font-weight: 550;
}