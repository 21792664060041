@keyframes popIn {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .card {
    animation: popIn 0.5s ease-out forwards;
  }
  
  .podium {
    transition: transform 0.3s ease;
  }
  
  .first {
    transform: translateY(-20px);
  }
  
  .second {
    transform: translateY(10px);
  }
  
  .third {
    transform: translateY(20px);
  }
  .card {
    transition: transform 0.3s ease;
  }
  
  /* Escala las tarjetas en pantallas pequeñas */
  @media (max-width: 767px) {
    .card {
      transform: scale(0.8); /* Ajusta este valor según lo necesites */
    }
  }
  
  /* Opcional: ajustar aún más para pantallas muy pequeñas */
  @media (max-width: 480px) {
    .card {
      transform: scale(0.6); /* Ajusta este valor según lo necesites */
    }
  }