.titleStyle {
  text-align: center;
  margin-top: 16px;
}

.rowStyle {
  padding: 0 16px;
  padding-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
}

