.swal2-custom {
    z-index: 9999; /* Asegúrate de que este valor sea más alto que el del modal de Ant Design */
  }
  .table-wrapper {
    position: relative;
  }
  
  .totals-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75%;
    background: #facbcb;
    display: flex;
    /* Opcional: agregar padding, bordes, etc. */
  }
  
  .totals-overlay > div {
    flex: 1;
    text-align: center; /* O 'left' o 'right', según necesites */
    font-weight: bold;
  }
  