.transfer_container {
  width: 100%;
}

.card {
  margin: 10px;
}

@media (max-width: 768px) {
  .transfer_container {
    display: block;
  }
}
