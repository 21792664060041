.menuSider {
    height: 100%;
  }
  
  .sider {
    overflow: auto !important;
    height: calc(100vh - 60px) !important;
    min-width: 250px !important;
    position: fixed !important;
    left: 0 !important;
    top: 61.84px !important;
    bottom: 0 !important;
    width: 250px !important;
    scrollbar-width: thin;
    scrollbar-color: var(--primary) #fff;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15); /* Sombra para destacar el Sider */
  }
  
  .siderTrigger {
    position: fixed;
    top: 61.84px;
    left: 0;
    height: calc(100vh - 60px);
    width: 20px; /* Ancho del área de activación */
    background-color: transparent;
  }
  
  .breadcrumbScroll {
    padding: 10px 22px 5px 22px !important;
    width: 100%;
    overflow: hidden;
  }
  
  .breadcrumbScroll ol {
    height: 30px;
    overflow-x: hidden !important;
    white-space: nowrap !important;
    flex-wrap: nowrap !important;
    flex: 1 !important;
  }
  
  .breadcrumbScroll ol:hover {
    overflow-x: auto !important;
  }
  
  /* Scroll en navegadores Webkit */
  .sider::-webkit-scrollbar {
    width: 6px;
  }
  
  .breadcrumbScroll ol::-webkit-scrollbar {
    height: 6px;
  }
  
  .sider::-webkit-scrollbar-thumb,
  .breadcrumbScroll ol::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 3px;
  }
  
  /* Scroll en navegadores como Firefox */
  .sider::-moz-scrollbar {
    width: 6px;
  }
  
  .breadcrumbScroll ol::-moz-scrollbar {
    height: 6px;
  }
  
  .sider::-moz-scrollbar-thumb,
  .breadcrumbScroll ol::-moz-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 3px;
  }
  
  .iconUser {
    font-size: 1.5rem !important;
    color: #fff;
  }
  
  .bottomNav {
    background-color: var(--primary);
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  
  .ContentContentPage {
    margin-top: 61.84px;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out; /* Animación suave para el desplazamiento */
  }
  
  .ContentPage {
    overflow: initial !important;
  }

  
  
  @media screen and (min-width: 992px) {
    .bottomNav {
      display: none;
    }
  }