/* styles.css */

.tranfer-table {
  width: 100%;
}

.card {
  margin: 10px;
}

@media (max-width: 768px) {
  .tranfer-table {
    display: block;
  }
  .ant-transfer-list-header{
    height: 80px; /* Cambia la altura según tus necesidades */
  }

  .title-transfer{
    font-size: 10px;
  }
}

 